import React, { useContext, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import LoginForm from "../components/login/loginForm";
import Layout from "../components/common/layout/layout";
import Logo from "../components/common/logo/logo";
import Link from "../components/common/link/link";
import i18n from "../../localization/i18n";
import * as rk from "../../localization/resourceKeys";
import styles from "./login.module.scss";
import Logger from "../../logger";
import Maintenance from "../components/maintenance";
import AccountVerificationStepFlow from "../components/accountVerification/accountVerificationStepFlow";
import { SessionContext } from "../../context/sessionContext";
import getMaintenanceMode from "../../utilities";

/* eslint-disable */
const Login = ({ maintenance, userFingerprint }) => {
  const { sessionDetails } = useContext(SessionContext);
  const [inMaintenanceMode, setInMaintenanceMode] = useState(maintenance?.inMaintenanceMode);
  const [step, setStep] = useState(i18n.t(rk.LOGIN_FLOW));
  const [email, setEmail] = useState("");
  const [partyId, setPartyId] = useState("");
  
  const [userAuthInfo, setUserAuthInfo] = useState({
    loginAuthenticatorCode: "",
    doesSecretExist: false,
    secret: "",
    secretURI: "",
  });

  useEffect(() => {
    async function getMode() {
      let response = await getMaintenanceMode()
      if (response === true) {
        setInMaintenanceMode("Y");
      } else setInMaintenanceMode("N");
    }
    getMode();
  }, [])

  const navigate = useNavigate();

  const brand = sessionDetails?.brand?.toLowerCase();
  const country = sessionDetails?.country?.toLowerCase();

  const isAudi = sessionDetails?.brand?.toLowerCase() === "audi";
  const isCanadaVW = brand === "vw" && country === "canada";

  const renderImage = () => {
    if (brand === "audi" && country === "canada") {
      return "./images/audi_canada/login.jpg";
    } else if (brand === "vw" && country === "usa") {
      return "./images/vw_usa/login_vw.jpg";
    } else if (brand === "vw" && country === "canada") {
      return "./images/vw_canada/login.jpg";
    }
  }
  
  return inMaintenanceMode === "Y" ? (
    <Maintenance />
  ) : (
    step === i18n.t(rk.ACCOUNT_VERIFICATION_FLOW) ? (
      <AccountVerificationStepFlow
        setStep={setStep}
        email={email}
        partyId={partyId}
        userFingerprint={userFingerprint}
        userAuthInfo={userAuthInfo}
        setUserAuthInfo={setUserAuthInfo}
      />
    ) : (
      <Layout imgSource={renderImage()}>
        <div className={styles["login-container"]}>
          <div className={isAudi ? styles["logo-login-screen-audi"] : styles["logo-login-screen"]}>
            <Logo />
          </div>
          {
            isAudi ?
              <div className="o-page-wrap">
                <h2 className={`u-mb-xlarge ${styles["c-section-heading__title-header"]} ${styles["text-normal"]}`}>{i18n.t(rk.TITLE_WELCOME)} {i18n.t(rk.LOGO_TEXT_AUDI)}</h2>
                <h3 className={`${styles["c-section-heading__title-text"]} u-mb-small`}>{i18n.t(rk.TITLE_LOGIN)}</h3>
                <div className={`u-mb-xlarge ${styles["forgot-content-audi"]}`}>
                  <span className={styles["new-to-audi-text"]}>{i18n.t(rk.TEXT_NEW_TO_AUDI)}</span>
                  <Link
                    value={i18n.t(rk.LABEL_CREATE_ACC_LINK)}
                    onClick={() => {
                      sessionStorage.setItem("isAccountLocked", false);
                      Logger.info("Registration Flow initiated", "/login", sessionDetails);
                      navigate(i18n.t(rk.LINK_CREATE_ACCOUNT));
                    }}
                    ariaLabel="Registration link"
                    testid="register-link"
                    icon="c-icon--[semantic-forward]"
                    customClass="u-pl-small"
                  />
                </div>
              </div>
              :
              <h3 className={styles["c-section-heading__title"]}>
            <span className={isCanadaVW ? styles["welcome-title-vw"] : styles["c-section-heading__title-text"]}>
              { isCanadaVW ? i18n.t(rk.TITLE_WELCOME_VW_1) : i18n.t(rk.TITLE_WELCOME)}
            </span>
            <span className={isCanadaVW ? styles["welcome-title-vw"] : styles["c-section-heading__title-text"]}>
              { isCanadaVW ? i18n.t(rk.TITLE_WELCOME_VW_2) : i18n.t(rk.TITLE_VW)}
            </span>
              </h3>
          }
          <div className={`${isAudi && styles["audi-container"]}`}>
            {step === i18n.t(rk.LOGIN_FLOW) && <LoginForm setStep={setStep} setEmail={setEmail} setPartyId={setPartyId} userFingerprint={userFingerprint} setUserAuthInfo={setUserAuthInfo}/>}
          </div>
          <p className={isAudi ?
             `o-page-wrap u-pv-large ${styles["forgot-content-audi-secondLink"]}` 
             : 
             (
              isCanadaVW ?
               styles["forgot-content-vf"] 
               : 
               styles["forgot-content"]
             )
          }>
            <span>{i18n.t(rk.TEXT_FORGOT)}</span>
            {/* Initiate Retrieve username flow */}
            <Link
              value={i18n.t(rk.LABEL_RETRIEVE_USERNAME_LINK)}
              onClick={() => {
                sessionStorage.setItem("isAccountLocked", false);
                Logger.info("Retrieve Username Flow initiated", "/login", sessionDetails);
                navigate(i18n.t(rk.LINK_RETRIEVE_USERNAME));
              }}
              ariaLabel="Retrieve username link"
              testid="retrieve-username-link"
            />
            <span>{i18n.t(rk.TEXT_OR)}</span>
            {/* Initiate Reset password flow */}
            <Link
              value={i18n.t(rk.LABEL_RESET_PSWD_LINK)}
              onClick={() => {
                sessionStorage.setItem("isAccountLocked", false);
                Logger.info("Reset Password Flow initiated", "/login", sessionDetails);
                navigate(i18n.t(rk.LINK_RESET_PASSWORD));
              }}
              ariaLabel="Reset password link"
              testid="reset-password-link"
            />
          </p>
          {
            !isAudi &&
            <p className={ isCanadaVW ? styles["new-to-vw-content-vf"] : styles["new-to-vw-content"] }>
              {isCanadaVW ? i18n.t(rk.TEXT_NEW_TO_VW_CAN) : i18n.t(rk.TEXT_NEW_TO_VW)}
              {/* Initiate registration flow */}
              <Link
                value={i18n.t(rk.LABEL_CREATE_ACC_LINK)}
                onClick={() => {
                  sessionStorage.setItem("isAccountLocked", false);
                  Logger.info("Registration Flow initiated", "/login", sessionDetails);
                  navigate(i18n.t(rk.LINK_CREATE_ACCOUNT));
                }}
                ariaLabel="Registration link"
                testid="register-link"
              />
            </p>
          }
          {
            isAudi ?
              <div className={`o-page-wrap u-pb-xlarge u-mb-xlarge ${styles["audi-container"]}`}>
                <div className="u-border u-text-center u-pv">
                  <p className={`u-mb-xsmall ${styles["text-bold"]}`}>
                    {`${i18n.t(rk.TEXT_LOOKING_FOR)} ${i18n.t(rk.TEXT_MYAUDI_LOGIN_PAGE)}`}
                  </p>
                  <Link
                    value={i18n.t(rk.LABEL_EXT_LOGIN_LINK)}
                    ariaLabel="myAudi login link"
                    target="_blank"
                    href={i18n.t(rk.LINK_MYAUDI_LOGIN_PAGE)}
                    onClick={() => Logger.info("Clicked myAudi login or create account link", "Location ==> /login", sessionDetails)}
                    testid="myAudi-login-link"
                    icon="c-icon--[semantic-forward]"
                  />
                </div>
              </div>
              :
              <div className={styles["card-container"]}>
                {
                  !isCanadaVW && 
                  (
                    <dl className="c-dl-split c-dl-split--responsive">
                  <dt className={styles["car-net-card-container"]}>
                    <article className="c-card">
                      <div className={styles["car-net-card"]}>
                        <p>{i18n.t(rk.TEXT_LOOKING_FOR)}</p>
                        <p>{i18n.t(rk.TEXT_CAR_NET_LOGIN_PAGE)}</p>
                        <div className={styles["card-link"]}>
                          <Link
                            ariaLabel="Car Net login link"
                            target="_blank"
                            href={i18n.t(rk.LINK_CAR_NET_LOGIN_PAGE)}
                            value={i18n.t(rk.LABEL_EXT_LOGIN_LINK)}
                            icon="c-icon--[semantic-arrow-right]"
                            onClick={() => Logger.info("Clicked Car-Net login or create account link", "Location ==> /login", sessionDetails)}
                            testid="car-net-login-link"
                          />
                        </div>
                      </div>
                    </article>
                  </dt>
                  <dd />
                  <dt className={styles["id-card-container"]}>
                    <article className="c-card">
                      <div className={styles["id-card"]}>
                        <p>{i18n.t(rk.TEXT_LOOKING_FOR)}</p>
                        <p>{i18n.t(rk.TEXT_ID_LOGIN_PAGE)}</p>
                        <div className={styles["card-link"]}>
                          <Link
                            ariaLabel="ID.4 login link"
                            target="_blank"
                            href={i18n.t(rk.LINK_ID_LOGIN_PAGE)}
                            value={i18n.t(rk.LABEL_EXT_LOGIN_LINK)}
                            icon="c-icon--[semantic-arrow-right]"
                            onClick={() => Logger.info("Clicked ID.4 login or create account link", "Location ==> /login", sessionDetails)}
                            testid="id-login-link"
                          />
                        </div>
                      </div>
                    </article>
                  </dt>
                  <dd />
                </dl>
                  )
                }

              </div>
          }
        </div>
      </Layout>
    )
  )
};

Login.propTypes = {
  maintenance: PropTypes.shape({
    inMaintenanceMode: PropTypes.string,
  }),
};

Login.defaultProps = {
  maintenance: {
    inMaintenanceMode: "",
  },
};
export default Login;
